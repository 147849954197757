import React, {useState} from "react";
import {Carousel, CarouselControl, CarouselIndicators} from 'reactstrap';
import {carouselItems} from "../res/data/carouselItems";
import {CarouselSlide} from "./CarouselSlide";
import './CarouselComponent.scss';

export const CarouselComponent: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const nextSlide = () => {
    if (isAnimating) return;
    const nextIndex = activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const prevSlide = () => {
    if (isAnimating) return;
    const prevIndex = activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
    setActiveIndex(prevIndex);
  }

  const goToIndex = (index: number) => {
    if (isAnimating) return;
    setActiveIndex(index);
  }

  const slides = carouselItems.map((item, i) => {
    return <CarouselSlide isActive={i === activeIndex}
                          onExiting={() => setIsAnimating(true)}
                          onExited={() => setIsAnimating(false)}
                          nextSlide={nextSlide}
                          item={item} key={item.type + i}/>
  })

  return (
    <Carousel className={'CarouselComponent'}
              activeIndex={activeIndex}
              next={nextSlide}
              previous={prevSlide}
              interval={false}>
      <CarouselIndicators items={carouselItems} activeIndex={activeIndex} onClickHandler={goToIndex}/>
      {slides}

      {activeIndex !== 0 ?
        <CarouselControl direction={'prev'} onClickHandler={prevSlide}/> : null}
      {activeIndex !== carouselItems.length - 1 ?
        <CarouselControl direction={'next'} onClickHandler={nextSlide}/> : null}
    </Carousel>
  )
}