import React from 'react';
import './App.scss';
import {CarouselComponent} from "./components/CarouselComponent";

function App() {
  return (
    <div className="App">
      <CarouselComponent/>
    </div>
  );
}

export default App;
