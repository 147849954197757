import React from "react";
import {AudioItemType, TextItemType} from "../res/data/carouselItems";
import {CarouselItem} from "reactstrap";
import './CarouselSlide.scss';
import {AudioSlide} from "./AudioSlide";
import {TextSlide} from "./TextSlide";

type CarouselSlideType = {
  onExiting(): void,
  onExited(): void,
  nextSlide(): void,
  isActive: boolean,
  item: AudioItemType | TextItemType
}

export const CarouselSlide: React.FC<CarouselSlideType> = ({onExiting, onExited, nextSlide, isActive, item}) => {
  const content = (() => {
    switch (item.type) {
      case 'AudioSlide':
        // @ts-ignore
        return <AudioSlide item={item} nextSlide={nextSlide} isActive={isActive}/>
      case 'TextSlide':
        // @ts-ignore
        return <TextSlide item={item}/>
    }
  })();

  return (
    <CarouselItem
      className={`CarouselSlide ${isActive ? 'active' : ''}`}
      onExiting={onExiting}
      onExited={onExited}>
      {content}
    </CarouselItem>
  )
}