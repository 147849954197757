import img01 from '../imgs/summerchallenge01.jpg';
import img02 from '../imgs/summerchallenge02.jpg';
import img03 from '../imgs/summerchallenge03.jpg';
import img04 from '../imgs/summerchallenge04.jpg';
import img05 from '../imgs/summerchallenge05.jpg';
import img06 from '../imgs/summerchallenge06.jpg';
import img07 from '../imgs/summerchallenge07.jpg';
import img08 from '../imgs/summerchallenge08.jpg';
import img09 from '../imgs/summerchallenge09.gif';
import img10 from '../imgs/summerchallenge10.jpg';
import img11 from '../imgs/summerchallenge11.jpg';
import img12 from '../imgs/summerchallenge12.jpg';
import img13 from '../imgs/summerchallenge13.jpg';
import img14 from '../imgs/summerchallenge14.jpg';
import img15 from '../imgs/summerchallenge15.gif';

import gifHappyBirthday from '../imgs/gif_happy-birthday.gif';
import gifNothingToSeeHere from '../imgs/gif_nothing-to-see-here.gif';

const tape01_intro = require('../audio/MiniTape_SideA_01_Intro.mp3');
const tape02_tb_fam_mueller = require('../audio/MiniTape_SideA_02_Telefonbeantworter-Familie-Müller.mp3');
const tape03_tb_ueli_binggeli = require('../audio/MiniTape_SideA_03_Telefonbeantorter-Binggeli-Ueli.mp3');
const tape04_tb_bani = require('../audio/MiniTape_SideA_04_Telefonbeantworter-Bani.mp3');
const tape05_tb_hostettler = require('../audio/MiniTape_SideA_05_Telefonbenatworter-Ernst-Hostettler.mp3');
const tape06_iv_intermission = require('../audio/MiniTape_SideA_06_Intermission_Interview.mp3');
const tape07_iv_schwander = require('../audio/MiniTape_SideA_07_Interview-Fabian-Schwander.mp3');
const tape08_iv_malo = require('../audio/MiniTape_SideA_08_Interview-Andrej-Malogajski.mp3');
const tape09_iv_schwander2 = require('../audio/MiniTape_SideA_09_Interview-Fabian-Schwander-Part2.mp3');
const tape10_iv_heitenried = require('../audio/MiniTape_SideA_10_Interview-Walter-aus-Heitenried.mp3');
const tape11_iv_wilhelm = require('../audio/MiniTape_SideA_11_Interview-Wilhelm-der-Österreicher.mp3');
const tape12_schuetzenfest1 = require('../audio/MiniTape_SideA_14_Schützenfest1.mp3');
const tape13_schuetzenfest2 = require('../audio/MiniTape_SideB_03_Schützenfest2.mp3');
const tape13_schuetzenfest3 = require('../audio/MiniTape_SideB_04_Schützenfest-Intermission.mp3');
const tape13_schuetzenfest4 = require('../audio/MiniTape_SideB_05_Schützenfest2-2.mp3');


export type AudioItemType = {
  type: string,
  imgSrc: string,
  imgAlt: string,
  trackSrc: string
}

export type TextItemType = {
  type: string,
  content: Array<{ elementType: string, text: string, src?: string }>
}

export const carouselItems: Array<AudioItemType | TextItemType> = [
  {
    type: 'TextSlide',
    content: [
      {
        elementType: 'img',
        text: 'Happy Birthday',
        src:gifHappyBirthday
      },
      {
        elementType: 'p',
        text: 'Geile Siech.'
      }
    ]
  },
  {
    type: 'TextSlide',
    content: [
      {
        elementType: 'p',
        text: 'Was ids chunnt isch nid immer eifach.'
      },
      {
        elementType: 'p',
        text: 'Mängisch tuets ou weh.'
      }
    ]
  },
  {
    type: 'TextSlide',
    content: [
      {
        elementType: 'h1',
        text: 'Wora me guet erkennt wi d Zyt vergange isch:'
      }
    ]
  },
  {
    type: 'TextSlide',
    content: [
      {
        elementType: 'p',
        text: 'Homophobie trage mir hüt nume no gäge inne.'
      }
    ]
  },
  {
    type: 'TextSlide',
    content: [
      {
        elementType: 'p',
        text: 'Und Telefonbeantworter müesse früecher mega wichtig gsi si.'
      },
      {
        elementType: 'p',
        text: 'Bsunders d Begrüessigsnachricht.'
      }
    ]
  },
  {
    type: 'AudioSlide',
    imgSrc: img01,
    imgAlt: 'foo',
    trackSrc: tape01_intro.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img02,
    imgAlt: 'bar',
    trackSrc: tape02_tb_fam_mueller.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img03,
    imgAlt: 'bar',
    trackSrc: tape03_tb_ueli_binggeli.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img04,
    imgAlt: 'bar',
    trackSrc: tape04_tb_bani.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img05,
    imgAlt: 'bar',
    trackSrc: tape05_tb_hostettler.default,
  },
  {
    type: 'TextSlide',
    content: [
      {
        elementType: 'h1',
        text: 'Interviews'
      },
      {
        elementType: 'p',
        text: 'Di einti oder anderi Längi chönnt me schnide.'
      }
    ]
  },
  {
    type: 'TextSlide',
    content: [
      {
        elementType: 'p',
        text: '...isch aber glich no geil.'
      }
    ]
  },
  {
    type: 'AudioSlide',
    imgSrc: img06,
    imgAlt: 'bar',
    trackSrc: tape06_iv_intermission.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img07,
    imgAlt: 'bar',
    trackSrc: tape07_iv_schwander.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img08,
    imgAlt: 'bar',
    trackSrc: tape08_iv_malo.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img09,
    imgAlt: 'bar',
    trackSrc: tape09_iv_schwander2.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img10,
    imgAlt: 'bar',
    trackSrc: tape10_iv_heitenried.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img11,
    imgAlt: 'bar',
    trackSrc: tape11_iv_wilhelm.default,
  },
  {
    type: 'TextSlide',
    content: [
      {
        elementType: 'h1',
        text: 'Schützefest? Oh yeah.'
      },
      {
        elementType: 'p',
        text: '...aber nüd für schwachi Närve.'
      },
      {
        elementType: 'h1',
        text: '🤷🏻'
      }
    ]
  },
  {
    type: 'AudioSlide',
    imgSrc: img12,
    imgAlt: 'bar',
    trackSrc: tape12_schuetzenfest1.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img13,
    imgAlt: 'bar',
    trackSrc: tape13_schuetzenfest2.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img14,
    imgAlt: 'bar',
    trackSrc: tape13_schuetzenfest3.default,
  },
  {
    type: 'AudioSlide',
    imgSrc: img15,
    imgAlt: 'bar',
    trackSrc: tape13_schuetzenfest4.default,
  },
  {
    type: 'TextSlide',
    content: [
      {
        elementType: 'img',
        text: 'Nothing to see here',
        src: gifNothingToSeeHere
      },
      {
        elementType: 'h1',
        text: '❤️'
      }
    ]
  }
];