import React from "react";
import {AudioPlayer} from "./AudioPlayer";
import {AudioItemType} from "../res/data/carouselItems";
import './AudioSlide.scss';

type AudioSlideType = {
  item: AudioItemType,
  isActive: boolean,
  nextSlide(): void
}

export const AudioSlide: React.FC<AudioSlideType> = ({item, isActive, nextSlide}) => {

  return (
    <div className={'AudioSlide'} style={{backgroundImage: `url(${item.imgSrc})`}}>
      <AudioPlayer track={item.trackSrc} isActive={isActive} onEnded={nextSlide}/>
    </div>
  )
}