type AudioPlayerType = {
  track: string,
  isActive: boolean,
  onEnded(): void
}

export const AudioPlayer: React.FC<AudioPlayerType> = ({track, isActive, onEnded}) => {
  const Player = () => <audio controls autoPlay={isActive} src={track} onEnded={onEnded}/>;

  return <Player/>
}