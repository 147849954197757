import React from "react";
import {TextItemType} from "../res/data/carouselItems";
import './TextSlide.scss';

type TextSlideType = {
  item: TextItemType
}

export const TextSlide: React.FC<TextSlideType> = ({item}) => {
  const content = item.content.map((el, i) => {
    switch (el.elementType) {
      case 'h1':
        return <h1 key={el.text}>{el.text}</h1>
      case 'p':
        return <p key={el.text}>{el.text}</p>
      case 'img':
        return <img key={el.src} src={el.src} alt={el.text}/>
      default:
        return null;
    }
  })

  return (
    <div className='TextSlide'>
      {content}
    </div>
  )
}